import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { AgentState } from "../models/agent.state";

export const agentSlice = createSlice({
    name: "agent",
    initialState: initialReduxState.agent,
    reducers: {
        setAgent: (state, action: PayloadAction<AgentState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setAgent } = agentSlice.actions;

export default agentSlice.reducer;
