import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { SlideChangeState, initialSlideChangeState } from '../models/change-on-slide';

export const slideChangeSlice = createSlice({
    name: "slideChange",
    initialState: initialReduxState.slideChange,
    reducers: {
        setSlideChange: (state, action: PayloadAction<SlideChangeState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setSlideChange } = slideChangeSlice.actions;

export default slideChangeSlice.reducer;
