import type { AppProps } from "next/app";
import Head from "next/head";
import React, { Fragment, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { onpreoTheme } from "@onpreo/components/src/theme";
import { initStore } from "../src/store";
import { Provider } from "react-redux";
import { Snacker } from "@onpreo/slices";

import "../public/global.css";
import Navigation from "src/components/navigation";
import { FEATURE_SIDEBAR } from "../src/utils/secrets";
import UserPilot from "../src/components/helper/userpilot-helper";

function MyApp({ Component, pageProps }: AppProps) {
    const store = initStore(pageProps.initialReduxState);
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) jssStyles.parentElement.removeChild(jssStyles);
    }, []);

    return (
        <Fragment>
            <Head>
                <title>onpreo - Preisassistent</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ThemeProvider theme={onpreoTheme}>
                <Provider store={store}>
                    <Snacker />
                    <UserPilot />
                    <CssBaseline />
                    {FEATURE_SIDEBAR ? (
                        <Navigation>
                            <Component {...pageProps} />
                        </Navigation>
                    ) : (
                        <Component {...pageProps} />
                    )}
                </Provider>
            </ThemeProvider>
        </Fragment>
    );
}

export default MyApp;
