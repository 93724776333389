import { ContactDocument } from "@onpreo/database";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const contactSlice = createSlice({
    name: "contact",
    initialState: initialReduxState.contact,
    reducers: {
        setContact: (state, action: PayloadAction<ContactDocument>) => {
            // @ts-ignore
            state.currentContact = action.payload;
            return state;
        },
        setGuests: (state, action: PayloadAction<ContactDocument[]>) => {
            // @ts-ignore
            state.guests = action.payload;
            return state;
        },
        setUpdatedGuestById: (state, action: PayloadAction<{ id: string; updatedContact: ContactDocument }>) => {
            // @ts-ignore
            state.guests = state.guests.map(g => (g["_id"] === action.payload.id ? action.payload.updatedContact : g));
        },
        setMode: (state, action: PayloadAction<{ mode: "default" | "workspace" | "demo" }>) => {
            state.mode = action.payload.mode;
        }
    }
});

export const { setContact, setGuests, setUpdatedGuestById, setMode } = contactSlice.actions;

export default contactSlice.reducer;
