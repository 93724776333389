import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RealEstateChangesDocument } from "@onpreo/database";
import { initialReduxState } from "../models";

export const realEstatesChangesSlice = createSlice({
    name: "realEstatesChanges",
    initialState: initialReduxState.realEstatesChanges,
    reducers: {
        setRealEstateChanges: (state, action: PayloadAction<{ active: number }>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        setGuestRealEstatesChanges: (state, action: PayloadAction<RealEstateChangesDocument[]>) => {
            // @ts-ignore
            state.guestRealEstatesChanges = action.payload;
            return state;
        },
        updateGuestRealEstateChanges: (state, action: PayloadAction<{ id: string; realEstateChanges: RealEstateChangesDocument }>) => {
            const { id, realEstateChanges } = action.payload;
            // @ts-ignore
            const index = state.guestRealEstatesChanges.findIndex(r => r._id === id);
            // @ts-ignore
            if (index !== -1) state.guestRealEstatesChanges[index] = realEstateChanges;
            else console.error("couldnt update real estate", id, realEstateChanges);
        }
    }
});

export const { setRealEstateChanges, updateGuestRealEstateChanges, setGuestRealEstatesChanges } = realEstatesChangesSlice.actions;

export default realEstatesChangesSlice.reducer;
