import { PriceAssistantDocument } from "@onpreo/database/src";

export type ExpertModeState = {
    filledTypes: {
        house: boolean;
        apartment: boolean;
        building: boolean;
    };
    // activePropertyType: "house" | "apartment" | "building";
    activePropertyType: string;
    basicStep: number;
    order: string[];
    priceAssistant: PriceAssistantDocument;
};

export const initialExpertModeState = {
    filledTypes: {
        house: false,
        apartment: false,
        building: false
    },
    activePropertyType: "",
    basicStep: 0,
    order: [],
    priceAssistant: {} as ExpertModeState["priceAssistant"]
};
