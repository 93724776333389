import { Action, AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator, configureStore, createAsyncThunk, ThunkAction } from "@reduxjs/toolkit";
import contactReducer from "./slices/contact.slice";
import realEstateReducer from "./slices/real-estate.slice";
import agentReducer from "./slices/agent.slice";
import { initialReduxState } from "./models";
import { useDispatch } from "react-redux";
import { sharedReducer } from "@onpreo/slices";
import { injectStore } from "@onpreo/upsy-daisy/client";
import guestRealEstateChangesReducer from "./slices/real-estate-changes.slice";

let store: ReturnType<typeof createStore>;

const createStore = (preloadedState: any) => {
    return configureStore({
        preloadedState: preloadedState ?? initialReduxState,
        reducer: {
            contact: contactReducer,
            realEstate: realEstateReducer,
            agent: agentReducer,
            realEstatesChanges: guestRealEstateChangesReducer,
            ...sharedReducer
        }
        // middleware: getDefaultMiddleware => getDefaultMiddleware.concat(OWN_MIDDLEWARE_GOES_HERE)
    });
};

export const initStore = (preloadedState?: any) => {
    let _store = store ?? createStore(preloadedState);
    if (preloadedState && store) {
        _store = createStore({
            ...store.getState(),
            ...preloadedState
        });
        store = undefined;
    }

    // create a new store for SSG and SSR
    if (typeof window === "undefined") return _store;
    // Create the store once in the client
    if (!store) {
        injectStore(_store);
        store = _store;
    }

    return _store;
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

type AsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
};
// Using this we get correctly typed thunks
export const createAppAsyncThunk = <Returned extends any, ThunkArg = void>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkConfig>,
    options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> => createAsyncThunk<Returned, ThunkArg>(typePrefix, payloadCreator, options);

export const useAppDispatch = () => useDispatch<AppDispatch>();
