import { LocationOnOutlined, HomeOutlined, CompareArrowsOutlined, Timeline } from "@material-ui/icons";
import React from "react";
import { Route } from "@onpreo/components";
import { RealEstateCategory, RealEstateConstruction } from "@onpreo/database/src/definitions/real-estate-helpers";

/* the jumpTwoSteps property is used if theres a slide only occuring depending on e.g. a checkbox like in the kapitalAnelger case
 then we need to jump 2 steps, so that the activeStep stays the same for slides behind that slide
 reasons are the "completedSteps" array , which is used to determine the progress of the user
*/

export const autoAssignActiveSteps = (routes, basePath) => {
    let stepCounter = 0;

    routes.forEach(route => {
        if (!route.divider && !route.title) {
            route.path = `${basePath}&activeStep=${stepCounter}`;
            route.activeStep = stepCounter;
            stepCounter++;

            if (route.collapse && route.views) {
                route.views.forEach(view => {
                    view.path = `${basePath}&activeStep=${stepCounter}`;
                    view.activeStep = stepCounter;
                    stepCounter++;
                    if (view.jumpTwoSteps) {
                        stepCounter++;
                    }
                });
            }
        }
    });

    return routes;
};

function defineRoutes(
    reId: string,
    tkn: string,
    propertyType: RealEstateCategory,
    kapitalAnelger: boolean,
    noTargetGroupSelected: boolean,
    construction?: RealEstateConstruction
) {
    if (propertyType === "EFH") {
        const routes = [
            { divider: true },
            { title: "Fortschritt" },
            {
                name: "Bewertung & Verkaufsstrategie"
            },
            {
                name: "Lage",
                collapse: true,
                state: "Lage",
                multiStates: ["Immobilie", "Marktvergleich", "Verkaufsstrategie"],
                icon: <LocationOnOutlined style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Durchschnittlicher Angebotspreis pro \u33A1",
                        inner: 1
                    },
                    { name: "Wohnfläche", inner: 1 },
                    {
                        name: "Durchschnittlicher Angebotspreis",
                        inner: 1
                    }
                ]
            },
            {
                name: "Immobilie",
                collapse: true,
                multiStates: ["Lage", "Marktvergleich", "Verkaufsstrategie"],
                state: "Immobilie",
                icon: <HomeOutlined style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Größe des Grundstücks",
                        inner: 1
                    },
                    {
                        name: "Art der Immobilie",
                        inner: 1
                    },
                    {
                        name: "Anzahl Zimmer/Geschosse",
                        inner: 1
                    },
                    { name: "Baujahr", inner: 1 },
                    { name: "Zustand, Sanierung & Renovierung", inner: 1 },
                    { name: "Equipment", inner: 1 },
                    { name: "Keller", inner: 1 },
                    { name: "Parkplatz", inner: 1 },
                    {
                        name: "Balkon/Terasse",
                        inner: 1
                    },
                    { name: "Garten", inner: 1 },
                    { name: "Immobiliennutzung", inner: 1 },
                    {
                        name: "Wertminderung/-steigerung",
                        inner: 1
                    }
                ]
            },
            {
                name: "Marktvergleich",
                collapse: true,
                multiStates: ["Immobilie", "Lage", "Verkaufsstrategie"],
                state: "Marktvergleich",
                icon: <CompareArrowsOutlined style={{ marginTop: "10px" }} />,
                views: kapitalAnelger
                    ? [
                          {
                              name: "Potentielle Käufer",
                              inner: 1
                          },
                          {
                              name: "Ertragsrechner",
                              inner: 1
                          }
                      ]
                    : [
                          {
                              name: "Potentielle Käufer",
                              inner: 1,
                              jumpTwoSteps: true
                          }
                      ]
            },
            {
                name: "Verkaufsstrategie",
                collapse: true,
                multiStates: ["Immobilie", "Lage", "Marktvergleich"],
                state: "Verkaufsstrategie",
                icon: <Timeline style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Marktentwicklung",
                        inner: 1
                    },
                    {
                        name: "Potentielle Interessenten",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    },
                    {
                        name: "Ihre Leistungen",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    },
                    {
                        name: "Ihre persönlichen Daten",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    }
                ]
            },
            { divider: true }
        ] as Route[];
        return routes;
    } else if (propertyType === "MFH") {
        const routes = [
            { divider: true },
            { title: "Fortschritt" },
            {
                name: "Bewertung & Verkaufsstrategie"
            },
            {
                name: "Lage",
                collapse: true,
                state: "Lage",
                multiStates: ["Immobilie", "Marktvergleich", "Verkaufsstrategie"],
                icon: <LocationOnOutlined style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Durchschnittlicher Angebotspreis pro \u33A1",
                        inner: 1
                    },
                    { name: "Wohnfläche", inner: 1 },
                    {
                        name: "Durchschnittlicher Angebotspreis",
                        inner: 1
                    }
                ]
            },
            {
                name: "Immobilie",
                collapse: true,
                multiStates: ["Lage", "Marktvergleich", "Verkaufsstrategie"],
                state: "Immobilie",
                icon: <HomeOutlined style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Wohn-/ Gewerbefläche",
                        inner: 1
                    },
                    {
                        name: "Anzahl der Vollgeschosse",
                        inner: 1
                    },
                    { name: "Dach", inner: 1 },
                    { name: "Fassade", inner: 1 },
                    { name: "Heizung", inner: 1 },
                    { name: "Zustand, Sanierung & Renovierung", inner: 1 },
                    { name: "Equipment", inner: 1 },
                    { name: "Keller", inner: 1 },
                    { name: "Parkplatz", inner: 1 },
                    {
                        name: "Balkon/Terasse",
                        inner: 1
                    },
                    { name: "Garten", inner: 1 },
                    {
                        name: "Immobiliennutzung",
                        inner: 1
                    },
                    {
                        name: "Wertminderung/-steigerung",
                        inner: 1
                    }
                ]
            },
            {
                name: "Marktvergleich",
                collapse: true,
                multiStates: ["Immobilie", "Lage", "Verkaufsstrategie"],
                state: "Marktvergleich",
                icon: <CompareArrowsOutlined style={{ marginTop: "10px" }} />,
                views: kapitalAnelger
                    ? [
                          {
                              name: "Potentielle Käufer",
                              inner: 1
                          },
                          {
                              name: "Ertragsrechner",
                              inner: 1
                          }
                      ]
                    : [
                          {
                              name: "Potentielle Käufer",
                              inner: 1,
                              jumpTwoSteps: true
                          }
                      ]
            },
            {
                name: "Verkaufsstrategie",
                collapse: true,
                multiStates: ["Immobilie", "Lage", "Marktvergleich"],
                state: "Verkaufsstrategie",
                icon: <Timeline />,
                views: [
                    {
                        name: "Marktentwicklung",
                        inner: 1
                    },
                    {
                        name: "Potentielle Interessenten",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    },
                    {
                        name: "Ihre Leistungen",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    },
                    {
                        name: "Ihre persönlichen Daten",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    }
                ]
            },
            { divider: true }
        ] as Route[];
        return routes;
    } else if (propertyType === "ETW") {
        const routes = [
            { divider: true },
            { title: "Fortschritt" },
            {
                name: "Bewertung & Verkaufsstrategie"
            },
            {
                name: "Lage",
                collapse: true,
                state: "Lage",
                multiStates: ["Immobilie", "Marktvergleich", "Verkaufsstrategie"],
                icon: <LocationOnOutlined style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Durchschnittlicher Angebotspreis pro \u33A1",
                        inner: 1
                    },
                    { name: "Wohnfläche", inner: 1 },
                    {
                        name: "Durchschnittlicher Angebotspreis",
                        inner: 1
                    }
                ]
            },
            {
                name: "Immobilie",
                collapse: true,
                multiStates: ["Lage", "Marktvergleich", "Verkaufsstrategie"],
                state: "Immobilie",
                icon: <HomeOutlined style={{ marginTop: "10px" }} />,
                views: [
                    { name: "Baujahr", inner: 1 },
                    {
                        name: "Zustand, Sanierung & Renovierung",
                        inner: 1
                    },
                    {
                        name: "Equipment",
                        inner: 1
                    },
                    { name: "Art der Wohnung", inner: 1 },
                    {
                        name: "Anzahl der Zimmer",
                        inner: 1
                    },
                    { name: "Keller", inner: 1 },
                    { name: "Parkplatz", inner: 1 },
                    {
                        name: "Balkon/Terasse",

                        inner: 1
                    },
                    { name: "Garten", inner: 1 },
                    {
                        name: "Immobiliennutzung",
                        inner: 1
                    },
                    {
                        name: "Wertminderung/-steigerung",
                        inner: 1
                    }
                ]
            },
            {
                name: "Marktvergleich",
                collapse: true,
                multiStates: ["Immobilie", "Lage", "Verkaufsstrategie"],
                state: "Marktvergleich",
                icon: <CompareArrowsOutlined style={{ marginTop: "10px" }} />,
                views: kapitalAnelger
                    ? [
                          {
                              name: "Potentielle Käufer",
                              inner: 1
                          },
                          {
                              name: "Ertragsrechner",
                              inner: 1
                          }
                      ]
                    : [
                          {
                              name: "Potentielle Käufer",
                              inner: 1,
                              jumpTwoSteps: true
                          }
                      ]
            },
            {
                name: "Verkaufsstrategie",
                collapse: true,
                multiStates: ["Immobilie", "Lage", "Marktvergleich"],
                state: "Verkaufsstrategie",
                icon: <Timeline style={{ marginTop: "10px" }} />,
                views: [
                    {
                        name: "Marktentwicklung",
                        inner: 1
                    },
                    {
                        name: "Potentielle Interessenten",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    },
                    {
                        name: "Ihre Leistungen",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    },
                    {
                        name: "Ihre persönlichen Daten",
                        inner: 1,
                        noTargetGroupSelected: noTargetGroupSelected
                    }
                ]
            },
            { divider: true }
        ] as Route[];
        return routes;
    } else return [];
}

export default defineRoutes;
