import {
    Box,
    Collapse,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    makeStyles,
    Typography,
    styled,
    Icon,
    Button,
    Grid,
    Tooltip
} from "@material-ui/core";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Link } from "../link";
import clsx from "clsx";
import componentStyles from "./style";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "@material-ui/core/Avatar";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuIcon from "@material-ui/icons/Menu";
import NavigateNext from "@material-ui/icons/NavigateNext";
import { FEATURE_SIDEBAR } from "../../../../../apps/price-assistant-app/src/utils/secrets";
import { getBooleanSideBarValue, getSideBarValue } from "../../../../../apps/price-assistant-app/src/store/selectors/sideBar-value.selector";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarState } from "../../../../../apps/price-assistant-app/src/store/slices/sideBar-value.slice";
import { useReduxSelector } from "../../../../../apps/expose-assistant-app/src/store/selectors/index";
//import sum from "apps/price-assistant-app/src/components/helper/arithmetic-functions"; //why does this import not work?
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import InfoIcon from "@material-ui/icons/Info";
import { RealEstateConstruction } from "@onpreo/database/src/definitions/real-estate-helpers";

function sum(arr: number[]): number {
    return arr.reduce((acc, current) => acc + current, 0);
}

export default sum;

const useStyles = makeStyles(componentStyles as any);

interface CommonProps {
    name?: string;
    // NOTE: you can either use miniName or icon, but not both
    // // // if you use both, only the icon will render
    miniName?: string;
    icon?: string | object;
    iconColor?:
        | "Primary"
        | "PrimaryLight"
        | "Error"
        | "ErrorLight"
        | "Warning"
        | "WarningLight"
        | "Info"
        | "InfoLight"
        | "Success"
        | "SuccessLight"
        | "Default";
    // paths when the tab should be selected
    connectedPath?: string[];
}

// this generates an anchor (<a href="href">..</a>) link
// this is a link that is sent outside the app
interface HrefProp {
    upgradeToPro?: boolean;
    href?: string;
}

// this generates a Link (<Link to="layout + path">..</Link>) link
// this is a link that is sent inside the app
interface LinkProp {
    path?: string;
    layout?: string;
    component?: FC;
}

interface CollapseProp {
    collapse?: boolean;
    // name of the collapse - needs to be unique
    state?: string;
    // if you have multi level collapses,
    // you need to set this array to all of the
    // collapses you wish to keep open when opening
    // the multi level collapse
    // multiStates: PropTypes.arrayOf(PropTypes.string),
    // views: PropTypes.arrayOf(PropTypes.oneOfType([hrefProp, linkProp])),
    // shows the depth of the current collapse in the multi level collapses
    inner?: number;
}

export interface RouteProps {
    // this is just a title without any action on it
    // you can think of it as a disabled link
    title?: string;
    // this is just a divider line
    divider?: boolean;
    showChip?: boolean;
    activeStep?: number;
    informationText?: boolean;
}

export type Route = RouteProps & CommonProps & HrefProp & LinkProp & CollapseProp;

interface SidebarProps {
    user?: {
        name?: string;
        imgSrc?: string;
        imgAlt?: string;
    };
    realEstate?: React.ReactNode;
    // use this to make the Sidebar open on responsive mode
    openResponsive: boolean;
    // callback for closing the Sidebar on responsive mode
    closeSidebarResponsive?: () => void;
    // this is the input/component that will be rendered on responsive
    // in our demo, we add this input component since the AdminNavbar
    // will not be visible on responsive mode
    input?: ReactNode;
    // this is the dropdown/component that will be rendered on responsive
    // in our demo, it is the same with the dropdown from the AdminNavbar
    // since the AdminNavbar will not be visible on responsive mode
    dropdown?: ReactNode;
    // NOTE: we recommend that your logo has the following dimensions
    // // 135x40 or 487x144 or a resize of these dimensions
    logo: {
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink?: string;
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink?: string;
        // the image src of the logo
        imgSrc: string;
        // the alt for the img
        imgAlt: string;
    };
    // links that will be displayed inside the component
    routes: Route[];
    showNotificationText?: boolean;
    onClick?: () => void;
    currentActiveStep?: number;
    completedSteps?: number[];
    guestMenuPreferences?: any;
    openSideBarState?: boolean;
    construction?: RealEstateConstruction;
}

const guestMenuPreferencesMap = {
    Immobilienbewertung: "priceFinder",
    Verkaufsfortschritt: "realEstateStatus",
    "Mein Exposé": "expose",
    "Notar- und Vertragsdaten": "notary"
};

export function SidebarPricefinder({
    routes,
    logo,
    openResponsive,
    closeSidebarResponsive,
    user,
    realEstate,
    showNotificationText,
    onClick,
    guestMenuPreferences,
    currentActiveStep,
    openSideBarState,
    completedSteps,
    construction
}: SidebarProps) {
    const classes = useStyles();
    const location = useRouter().asPath;
    const [state, setState] = useState({});
    const [openState, setOpenState] = useState(openSideBarState);
    const [miniActive, setMiniActive] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);

    function defineItemChecks(prop) {
        if (!prop.collapse) {
            if (completedSteps.includes(Number(prop.activeStep))) {
                return <CheckCircleOutlineOutlinedIcon />;
            } else {
                return null;
            }
        }
    }

    function defineCollapseChecks(prop) {
        if (prop.views) {
            let tmpArray = [];
            for (let i = 0; i < prop.views.length; i++) {
                const tmp = completedSteps.includes(prop.views[i].activeStep) ? 1 : 0;
                tmpArray.push(tmp);
            }
            if (sum(tmpArray) === prop.views.length) {
                return true;
            }
        } else {
            return false;
        }
    }

    function defineListItemStyles(prop) {
        /* if ( prop.activeStep < currentActiveStep ) {
            const listItemStyles = {
                backgroundColor: prop.iconColor,
                border: showNotificationText && prop.showChip ? "1px solid green" : "",
                textDecoration: "none!important"
            }
            return listItemStyles;
        } */
        /* if (completedSteps.includes(Number(prop.activeStep))){
            const listItemStyles = {
                backgroundColor: "red",//"#525f7f", //"#00000014",//"#f8f9fe"
                border: showNotificationText && prop.showChip ? "1px solid green" : "",
                textDecoration: "none!important",
                color: "white"
            }
            return listItemStyles;
        } */
        if (prop.activeStep === currentActiveStep) {
            //color for current Step maybe needs to be adjusted
            const listItemStyles = {
                backgroundColor: "#525f7f", //"#00000014",//"#f8f9fe"
                border: showNotificationText && prop.showChip ? "1px solid green" : "",
                textDecoration: "none!important",
                color: "white"
            };
            return listItemStyles;
        }
        /*         if (prop.views) {
            let tmpArray = [];
            for( let i = 0; i < prop.views.length; i++) {
                const tmp = completedSteps.includes(prop.views[i].activeStep) ? 1 : 0;
                tmpArray.push(tmp)
            }
            if( sum(tmpArray) === prop.views.length) {
                const listItemStyles = {
                    backgroundColor: "red",//"#525f7f", //"#00000014",//"#f8f9fe"
                    border: showNotificationText && prop.showChip ? "1px solid green" : "",
                    textDecoration: "none!important",
                    color: "white"
                }
                return listItemStyles;
            }   
        }    */
    }

    function priceFinderHref(prop) {
        if (showNotificationText && prop.showChip) {
            return `/premium-content?tabValue=2`;
        } else {
            //return (prop.activeStep <= currentActiveStep) ? prop.path : location
            return prop.path;
        }
    }

    useEffect(() => {
        setState(getCollapseStates(routes));
    }, []);

    /*     useEffect(() => {
        markedArray.push(currentActiveStep);
        console.log(markedArray);
    }, [currentActiveStep]); */

    useEffect(() => {
        currentActiveStep > -1 ? setOpenState(true) : null;
    }, [openSideBarState]);

    const openSidebar = () => {
        setOpenState(true);
        //dispatch(setSideBarState({value: true}))
    };

    const closeSidebar = (showNotificationText?: boolean, showChip?: boolean) => {
        //dispatch(setSideBarState({value: false}))
        setOpenState(false);
        if (showNotificationText && showChip) onClick();
    };

    // makes the sidenav normal on hover (actually when mouse enters on it)
    const onMouseEnterSidenav = () => {
        if (miniActive && !mouseEnter) {
            setMiniActive(false);
            setMouseEnter(true);
        }
    };

    // makes the sidenav mini on hover (actually when mouse leaves from it)
    const onMouseLeaveSidenav = () => {
        if (mouseEnter) {
            setMiniActive(true);
            setMouseEnter(false);
        }
    };

    // this creates the intial state of this component based on the collapse routes
    // that it gets through routes
    const getCollapseStates = routes => {
        let initialState = {};
        routes.map(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    const getCollapseInitialState = routes => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (location.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    };

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    const handleMenuClose = () => {
        if (window.innerWidth < 1200) {
            // toggleSidenav();
        }
    };

    // creates the links that appear in the left menu / Sidebar
    const createLinks = routes => {
        return routes?.map((prop, key) => {
            if (prop.redirect) {
                return null;
            } else if (prop.divider) {
                return <Divider key={key} classes={{ root: classes.divider }} />;
            } else if (prop.title) {
                if (miniActive) {
                    return null;
                }
                return (
                    <Typography key={key} variant="h6" component="h6" classes={{ root: classes.title }}>
                        {prop.title}
                    </Typography>
                );
            } else if (guestMenuPreferences) {
                if (Object.keys(guestMenuPreferencesMap).includes(prop.name) && !guestMenuPreferences[guestMenuPreferencesMap[prop.name]]) return null;
            }
            let textContent = (
                <Box id={`sidebar-tc-${prop.name}`} alignItems={"center"} display={"flex"} style={{ marginLeft: prop.inner ? prop.inner * 16 : 0 }}>
                    <Box minWidth="2.25rem" display="flex" alignItems="center">
                        {defineItemChecks(prop)}
                        {typeof prop.icon === "string" ? (
                            <Box component="i" className={prop.icon + " " + classes["text" + prop.iconColor]} marginLeft={miniActive ? "-.25rem" : ""} />
                        ) : null}
                        {typeof prop.icon !== "string" ? (
                            <Box style={{ marginLeft: miniActive ? "-.25rem" : "", marginTop: "-.25rem" }} className={classes["text" + prop.iconColor]}>
                                {prop.icon}
                            </Box>
                        ) : null}
                        {prop.icon === undefined && prop.miniName !== undefined ? (
                            <Box component="span" className={classes["text" + prop.iconColor]}>
                                {prop.miniName}
                            </Box>
                        ) : null}
                    </Box>
                    <Box>{miniActive ? null : prop.name}</Box>
                    {showNotificationText && prop.showChip && (
                        <Box style={{ position: "absolute", right: 4, top: 2 }}>
                            <Typography style={{ fontSize: 8, color: "green" }}>neue Buchungen </Typography>
                        </Box>
                    )}
                </Box>
            );
            if (prop.collapse) {
                const st = {};
                st[prop["state"]] = !state[prop.state];
                if (prop.multiStates) {
                    prop.multiStates.forEach(item => {
                        st[item] = state[item];
                    });
                }
                return (
                    <Fragment key={key}>
                        <ListItem
                            id={`sidebar-${prop.name}`}
                            component={"a"}
                            href="#mui"
                            onClick={e => {
                                e.preventDefault();
                                setState(st);
                            }}
                            classes={{
                                root: clsx(classes.listItemRoot, {
                                    [classes.listItemRootCollapseActive]: getCollapseInitialState(prop.views)
                                })
                            }}
                            style={defineListItemStyles(prop)}
                        >
                            {textContent}
                            {/* {miniActive ? null : 
                            (<Box
                                component={!defineCollapseChecks(prop) ? NavigateNext : undefined}
                                marginLeft="auto"
                                width="1rem!important"
                                height="1rem!important"
                                display="flex"
                                justifyContent="flex-end"
                            >
                            {defineCollapseChecks(prop) ? (
                            <Grid container>
                                <NavigateNext 
                                    className={clsx(classes.listItemRootCollapseIcon, {
                                        [classes.listItemRootCollapseActiveIcon]: state[prop.state]
                                    })}
                                    />
                                    <CheckCircleOutlineOutlinedIcon/>
                            </Grid>
                            ) 
                            : null
                            }
                            </Box>
                            )} */}
                            {miniActive ? null : (
                                <Box
                                    component={NavigateNext}
                                    marginLeft="auto"
                                    width="1rem!important"
                                    height="1rem!important"
                                    display="flex"
                                    justifyContent="flex-end"
                                    className={clsx(classes.listItemRootCollapseIcon, {
                                        [classes.listItemRootCollapseActiveIcon]: state[prop.state]
                                    })}
                                ></Box>
                            )}
                        </ListItem>
                        <Collapse in={state[prop.state]} unmountOnExit className={classes.collapseRoot}>
                            <List classes={{ root: classes.listRootCollapse }}>{createLinks(prop.views)}</List>
                        </Collapse>
                    </Fragment>
                );
            }
            // else if (prop.href) {
            //     if (prop.noTargetGroupSelected) {
            //         return (
            //             <ListItem
            //                 id={`sidebar-${prop.name}`}
            //                 key={key}
            //                 // component={"a"}
            //                 onClick={() => closeSidebar(showNotificationText, prop.showChip)}
            //                 classes={{
            //                     root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro : ""),
            //                     selected: classes.listItemSelected
            //                 }}
            //                 selected={prop.upgradeToPro === true}
            //             >
            //                 <a href={prop.href} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
            //                     {textContent}
            //                 </a>
            //             </ListItem>
            //         );
            //     } else {
            //         return (
            //             <ListItem
            //                 id={`sidebar-${prop.name}`}
            //                 key={key}
            //                 // component={"a"}
            //                 onClick={() => closeSidebar(showNotificationText, prop.showChip)}
            //                 classes={{
            //                     root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro : ""),
            //                     selected: classes.listItemSelected
            //                 }}
            //                 selected={prop.upgradeToPro === true}
            //             >
            //                 <a href={prop.href} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
            //                     {textContent}
            //                 </a>
            //             </ListItem>
            //         );
            //     }
            // }
            else {
                if (prop.noTargetGroupSelected) {
                    return (
                        //<Link href={showNotificationText && prop.showChip ? `/premium-content?tabValue=2` : prop.path}>
                        <Link href={location} className={classes.noUnderlinedLink}>
                            <Tooltip title={"Käufergruppe muss ausgewählt werden"}>
                                <ListItem
                                    id={`sidebar-${prop.name}`}
                                    key={key}
                                    // onClick={() => closeSidebar(showNotificationText, prop.showChip)}
                                    onClick={() => null}
                                    classes={{
                                        root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro : ""),
                                        selected: classes.listItemSelected
                                    }}
                                    //style={{ border: showNotificationText && prop.showChip ? "1px solid green" : "" }}
                                    // style={defineListItemStyles(prop)}
                                    style={{ color: "rgba(0, 0, 0, 0.26)", backgroundColor: "rgba(0, 0, 0, 0.12)" }}
                                    selected={location === prop.path || prop.upgradeToPro === true || prop.connectedPath?.includes(location)}
                                >
                                    {textContent}
                                </ListItem>
                            </Tooltip>
                        </Link>
                    );
                } else {
                    return (
                        //<Link href={showNotificationText && prop.showChip ? `/premium-content?tabValue=2` : prop.path}>
                        <Link href={priceFinderHref(prop)} className={classes.noUnderlinedLink}>
                            <ListItem
                                id={`sidebar-${prop.name}`}
                                key={key}
                                onClick={() => closeSidebar(showNotificationText, prop.showChip)}
                                classes={{
                                    root: classes.listItemRoot + (prop.upgradeToPro ? " " + classes.listItemRootUpgradeToPro : ""),
                                    selected: classes.listItemSelected
                                }}
                                //style={{ border: showNotificationText && prop.showChip ? "1px solid green" : "" }}
                                style={defineListItemStyles(prop)}
                                selected={location === prop.path || prop.upgradeToPro === true || prop.connectedPath?.includes(location)}
                            >
                                {textContent}
                            </ListItem>
                        </Link>
                    );
                }
            }
        });
    };
    let logoImage = <img alt={logo.imgAlt} className={classes.logoClasses} src={logo.imgSrc} />;
    let logoObject =
        logo && logo.innerLink ? (
            <Link href={logo.innerLink} className={classes.logoLinkClasses}>
                {logoImage}
            </Link>
        ) : logo && logo.outterLink ? (
            <a href={logo.outterLink} className={classes.logoLinkClasses}>
                {logoImage}
            </a>
        ) : null;

    const desktopObject = (
        <Fragment>
            {/* <IconButton color={"primary"} onClick={() => closeSidebar(false)} classes={{ root: classes.menuOpenIcon }}>
                <Box component={MenuOpenIcon} width="2rem!important" height="2rem!important" />
            </IconButton> */}
            <Box display="flex" justifyContent={"center"} alignItems="center">
                {miniActive ? null : logoObject}
            </Box>
            {user && (
                <Fragment>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Avatar
                            alt={user?.imgAlt ?? "..."}
                            src={user?.imgSrc ?? "/favicon.png"}
                            classes={{
                                root: classes.avatarWrapper
                            }}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Typography align="center">{user?.name ?? "insert:username"}</Typography>
                    </Box>
                </Fragment>
            )}
            {realEstate}
            <List classes={{ root: classes.listRoot }}>
                {createLinks(routes)}
                {/* <Typography style={{ marginLeft: "7px", marginRight: "7px" }} variant="h5" color="textPrimary"> Grau unterlegte Felder können nicht angeklickt werden. 
                Bei der Navigation zu einem vorherigen Feld gehen alle Eingaben, die chronologisch hinter dem angeklickten Feld liegen, verloren!
                </Typography> */}
                <a
                    href="https://intercom.help/onpreo/de/articles/8446015-der-preis-finder-fur-einfamilienhauser"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Typography align="center" style={{ fontSize: 10, marginRight: 0 }}>
                        <InfoIcon className={classes.infoIcon} />
                        <span style={{ marginLeft: 16 }}> Finden Sie hier mehr Informationen </span>
                        <br />
                        <span> zum Preis-Finder </span>
                    </Typography>
                </a>
            </List>
        </Fragment>
    );

    const mobileObject = (
        <Fragment>
            {/* <IconButton color={"primary"} onClick={() => closeSidebar(false)} classes={{ root: classes.menuOpenIcon }}>
                <Box component={MenuOpenIcon} width="2rem!important" height="2rem!important" />
            </IconButton> */}
            <Box display="flex" justifyContent={"center"} alignItems="center" pl={2}>
                {miniActive ? null : logoObject}
            </Box>
            {user && (
                <Fragment>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Avatar
                            alt={user?.imgAlt ?? "..."}
                            src={user?.imgSrc ?? "/favicon.png"}
                            classes={{
                                root: classes.avatarWrapper
                            }}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"center"} alignItems="center">
                        <Typography align="center">{user?.name ?? "insert:username"}</Typography>
                    </Box>
                </Fragment>
            )}
            {realEstate}
            <List classes={{ root: classes.listRoot }}>
                {createLinks(routes)}
                {/* <Typography style={{ fontSize: "12.5px", marginLeft: "8px", marginRight: "8px"}}> Grau unterlegte Felder können nicht angeklickt werden. 
                Bei der Navigation zu einem vorherigen Feld gehen alle Eingaben, die chronologisch hinter dem angeklickten Feld liegen, verloren!
                </Typography> */}
                <a
                    href="https://intercom.help/onpreo/de/articles/8446015-der-preis-finder-fur-einfamilienhauser"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                >
                    <Typography align="center" style={{ fontSize: 10, marginRight: 0 }}>
                        <InfoIcon className={classes.infoIcon} />
                        <span style={{ marginLeft: 16 }}> Finden Sie hier mehr Informationen </span>
                        <br />
                        <span> zum Preis-Finder </span>
                    </Typography>
                </a>
            </List>
        </Fragment>
    );

    return (
        <Fragment>
            <Hidden lgDown>
                {currentActiveStep > -1 ? (
                    <IconButton color={"secondary"} onClick={openSidebar} classes={{ root: classes.menuIconPriceFinder }}>
                        <Box component={MenuIcon} width="2rem!important" height="2rem!important" />
                    </IconButton>
                ) : null}
                <Drawer
                    variant={"temporary"}
                    anchor="left"
                    open={openState}
                    onClose={closeSidebarResponsive}
                    ModalProps={{ onBackdropClick: () => closeSidebar(false) }}
                    classes={{
                        paper: clsx({ [classes.drawerDockedMiniActive]: miniActive }),
                        docked: clsx({ [classes.drawerPaperMiniActive]: miniActive })
                    }}
                    onMouseEnter={onMouseEnterSidenav}
                    onMouseLeave={onMouseLeaveSidenav}
                >
                    {typeof navigator !== "undefined" && navigator.platform.indexOf("Win") > -1 ? (
                        <PerfectScrollbar>{desktopObject}</PerfectScrollbar>
                    ) : (
                        desktopObject
                    )}
                </Drawer>
            </Hidden>
            <Hidden xlUp>
                {currentActiveStep > -1 ? (
                    <IconButton color={"secondary"} onClick={openSidebar} classes={{ root: classes.menuIconPriceFinder }}>
                        <Box component={MenuIcon} width="2rem!important" height="2rem!important" />
                    </IconButton>
                ) : null}
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={openState}
                    onClose={closeSidebarResponsive}
                    ModalProps={{ onBackdropClick: () => closeSidebar(false) }}
                >
                    {typeof navigator !== "undefined" && navigator.platform.indexOf("Win") > -1 ? (
                        <PerfectScrollbar>{mobileObject}</PerfectScrollbar>
                    ) : (
                        mobileObject
                    )}
                </Drawer>
            </Hidden>
        </Fragment>
    );
}
