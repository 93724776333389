export interface UserState {
    id: string;
    _id: string;
    name: string;
    email: string;
    workspaceId: string;
    company: string;
}

export const initialUserState = {
    id: "",
    _id: "",
    name: "",
    email: "",
    workspaceId: "",
    company: ""
} as UserState;
