import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { RealEstateState } from "../models/real-estate";

export const realEstateSlice = createSlice({
    name: "realEstate",
    initialState: initialReduxState.realEstate,
    reducers: {
        setRealEstate: (state, action: PayloadAction<RealEstateState>) => {
            // @ts-ignore
            state = action.payload;
            return state;
        }
    }
});

export const { setRealEstate } = realEstateSlice.actions;

export default realEstateSlice.reducer;
