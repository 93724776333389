import { AgentSettings, ImageDetails, MetaInfo, StripeBilling } from "@onpreo/database/src";

export interface AgentState {
    id: string;
    meta: MetaInfo;
    workspace: {
        id: string;
        agentSettings: AgentSettings;
        agentImage: ImageDetails;
    };
    gender?: string;
    company: Pick<StripeBilling, "company" | "phone" | "address" | "website">;
}

export const initialAgentState = {} as AgentState;
