import React, { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import defineRoutes, { autoAssignActiveSteps } from "./routes";
import { useRouter } from "next/router";
import {
    getLogoUseInPriceAssistant,
    getRealEstate,
    getRealEstateCat,
    getRealEstateConstruction,
    getWorkspaceLogo
} from "src/store/selectors/real-estate.selectors";
import { FEATURE_SIDEBAR } from "src/utils/secrets";
import { SidebarPricefinder } from "../../../../../packages/components/src/navigation/sidebar-pricefinder/index";
import { getBooleanSideBarValue } from "src/store/selectors/sideBar-value.selector";
import { getCompletedSteps } from "src/store/selectors/completed-steps.selectors";
import { useAppDispatch } from "../../../../guest-app/src/store";
import { setCompletedStepsState } from "src/store/slices/completed-steps.slice";
import { PRICE_ASSISTANT_URL, PROTOCOL } from "src/utils/secrets";

const Navigation: FC = props => {
    const router = useRouter();
    const realEstateCategory = useSelector(getRealEstateCat);
    const extrasLogoUse = useSelector(getLogoUseInPriceAssistant);
    const extrasLogo = useSelector(getWorkspaceLogo);
    const imgSrc = extrasLogoUse && extrasLogo?.src !== undefined ? extrasLogo?.src : "/logo.png";
    const completedSteps = useSelector(getCompletedSteps);
    const dispatch = useAppDispatch();
    const state = useSelector(getRealEstate);
    const potentialBuyers = state?.potentialBuyers ?? {
        singles: false,
        paare: false,
        senioren: false,
        familien: false,
        kapitalAnleger: false
    };
    const hasAllFalseValues = Object.values(potentialBuyers).every(value => value === false);
    const kapitalAnleger = potentialBuyers?.kapitalAnleger ?? false;
    const sidebarValue = useSelector(getBooleanSideBarValue);
    const construction = useSelector(getRealEstateConstruction);

    const basePath = `${PROTOCOL}${PRICE_ASSISTANT_URL}/?re=${String(router.query.re)}&auth=${String(router.query.auth)}`;
    const routes = defineRoutes(String(router.query.re), String(router.query.auth), realEstateCategory, kapitalAnleger, false, construction);
    const [routesWithSteps, setRoutesWithSteps] = useState(autoAssignActiveSteps(routes, basePath));

    useEffect(() => {
        if (!completedSteps.includes(Number(router.query.activeStep))) {
            dispatch(setCompletedStepsState({ completedStepsArray: [...completedSteps, Number(router.query.activeStep)] }));
        }
    }, [router.query.activeStep]);

    useEffect(() => {
        if (!!realEstateCategory) {
            const newRoutes = defineRoutes(String(router.query.re), String(router.query.auth), realEstateCategory, kapitalAnleger, false, construction);
            setRoutesWithSteps(autoAssignActiveSteps(newRoutes, basePath));
        }
    }, [router.query.activeStep, kapitalAnleger, realEstateCategory]);

    return (
        <Fragment>
            {FEATURE_SIDEBAR ? (
                <SidebarPricefinder
                    closeSidebarResponsive={() => null}
                    openResponsive={true}
                    routes={routesWithSteps}
                    logo={{ imgSrc: imgSrc, imgAlt: "onpreo", innerLink: router.asPath }}
                    currentActiveStep={Number(router.query.activeStep)}
                    openSideBarState={sidebarValue}
                    completedSteps={completedSteps}
                    construction={construction}
                />
            ) : null}
            <div id={"app-content"}>{props.children}</div>
        </Fragment>
    );
};

export default Navigation;
