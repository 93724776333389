import { FC } from "react";
import { LinkProps } from "next/link";
import { default as NextLink } from "next/link";

interface OnpreoLinkProps extends LinkProps {
    className?: string;
}

export const Link: FC<OnpreoLinkProps> = props => {
    const { className, children, ...linkProps } = props;

    return (
        <NextLink className={className} style={{ cursor: "pointer" }} {...linkProps}>
            {children}
        </NextLink>
    );
};
