export type BrokerEntryState = {
    brokerEntryArea: number;
    brokerEntryTypeRealEstate: number;
    brokerEntryHouseType1: number;
    brokerEntryHouseType2: number;
    brokerEntryRooms: number;
    brokerEntryYear: number;
    brokerEntryMaintenance: number;
    brokerEntryUse: number;
    brokerEntryBasement: number;
    brokerEntryParking: number;
    brokerEntryBalcony: number;
    brokerEntryTerasse: number;
    brokerEntryGarden: number;
    brokerEntryImpairment: number[];
    brokerEntryServices: string[];
    brokerEntryMarket: number;
    brokerEntryInvestor: number;
    brokerEntryApartment: number;
    brokerEntryFloors: number;
    brokerEntryRoof: number;
    brokerEntryFacade: number;
    brokerEntryHeating: number;
    brokerEntryRennovation: number;
    brokerEntryEquipment: number;
};

export const initialBrokerEntryState = {
    brokerEntryArea: null,
    brokerEntryTypeRealEstate: null,
    brokerEntryHouseType1: null,
    brokerEntryHouseType2: null,
    brokerEntryRooms: null,
    brokerEntryYear: null,
    brokerEntryMaintenance: null,
    brokerEntryUse: null,
    brokerEntryBasement: null,
    brokerEntryParking: null,
    brokerEntryBalcony: null,
    brokerEntryTerasse: null,
    brokerEntryGarden: null,
    brokerEntryImpairment: [0, 0, 0],
    brokerEntryServices: ["", "", ""],
    brokerEntryMarket: null,
    brokerEntryInvestor: null,
    brokerEntryApartment: null,
    brokerEntryFloors: null,
    brokerEntryRoof: null,
    brokerEntryFacade: null,
    brokerEntryHeating: null,
    brokerEntryRennovation: null,
    brokerEntryEquipment: null
};
