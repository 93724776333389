const componentStyles = (theme: any) => ({
    collapseRoot: {
        "& $listItemRoot": {
            fontSize: ".8125rem",
            padding: ".65rem 1rem !important"
        }
    },
    listRoot: {
        height: "100%"
    },
    listRootCollapse: {
        padding: "0!important"
    },
    listItemRoot: {
        display: "flex",
        fontSize: ".9rem",
        padding: ".8125rem 1rem !important",
        borderRadius: ".375rem",
        marginRight: ".5rem",
        marginLeft: ".5rem",
        width: "auto",
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.palette.primary.main
        }
    },
    listItemRootCollapseActive: {
        // background: theme.palette.gray[100],
        color: theme.palette.white
        // backgroundColor: theme.palette.primary.main
    },
    listItemRootCollapseIcon: {
        transition: "all .15s ease"
    },
    listItemRootCollapseActiveIcon: {
        transform: "rotate(90deg)"
    },
    listItemRootUpgradeToPro: {
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: "10px"
        },
        "&,&:hover": {
            background: theme.palette.gray[100] + "!important"
        },
        "&:before": {
            display: "none"
        }
    },
    listItemSelected: {
        backgroundColor: theme.palette.primary.main,
        "&$listItemRoot,&$listItemRoot:hover": {
            color: theme.palette.primary.light
        }
    },
    lightColor: theme.palette.primary.light,
    listItemIconRoot: {
        minWidth: "2.25rem",
        fontSize: ".9375rem",
        lineHeight: "1.5rem",
        display: "inline-block",
        top: "2px"
    },
    divider: {
        marginBottom: "1rem",
        marginTop: "1rem",
        marginLeft: "1.5rem",
        marginRight: "1.5rem"
    },
    title: {
        paddingTop: ".25rem",
        paddingBottom: ".25rem",
        fontSize: ".75rem",
        textTransform: "uppercase",
        letterSpacing: ".04em",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        color: theme.palette.gray[600]
        //textAlign: "justify"
    },
    logoClasses: {
        marginTop: theme.spacing(2),
        maxHeight: "2rem",
        maxWidth: "100%",
        verticalAlign: "middle",
        borderStyle: "none"
    },
    workSpaceLogo: {
        //marginTop: theme.spacing(2),
        maxHeight: "4rem",
        maxWidth: "100%",
        verticalAlign: "middle",
        borderStyle: "none"
    },
    logoLinkClasses: {
        fontSize: "1.25rem",
        lineHeight: "inherit",
        whiteSpace: "nowrap",
        textDecoration: "none",
        display: "block",
        textAlign: "center"
    },
    menuIcon: {
        position: "sticky",
        // Zindex one less than the sidebar
        zIndex: 1199,
        top: "8px",
        left: "16px",
        backgroundColor: "rgb(211,211,211, .25)"
    },
    menuOpenIcon: {
        position: "absolute",
        top: "17px",
        left: "194px"
    },
    menuIconPriceFinder: {
        position: "fixed",
        // Zindex one less than the sidebar
        zIndex: 100,
        //zIndex: 9999,
        //bottom: "8px",
        bottom: "20px",
        left: "20px",
        backgroundColor: theme.palette.primary.main, //"rgb(211,211,211, .25)"
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.palette.primary.light
        }
    },
    menuOpenIconPriceFinder: {
        position: "absolute",
        bottom: "18px",
        left: "194px"
    },
    textPrimary: {
        color: theme.palette.primary.main
    },
    textPrimaryLight: {
        color: theme.palette.primary.light
    },
    textError: {
        color: theme.palette.error.main
    },
    textErrorLight: {
        color: theme.palette.error.light
    },
    textWarning: {
        color: theme.palette.warning.main
    },
    textWarningLight: {
        color: theme.palette.warning.light
    },
    textInfo: {
        color: theme.palette.info.main
    },
    textInfoLight: {
        color: theme.palette.info.light
    },
    textSuccess: {
        color: theme.palette.success.main
    },
    textSuccessLight: {
        color: theme.palette.success.light
    },
    textDefault: {
        color: theme.palette.dark.main
    },
    menuPaper: {
        width: "calc(100% - 2rem)"
    },
    outlineNone: {
        outline: "none!important"
    },
    drawerDockedMiniActive: {
        width: "62px",
        overflowX: "hidden"
    },
    drawerPaperMiniActive: {
        width: "62px",
        overflowX: "hidden"
    },
    avatarWrapper: {
        width: 75,
        height: 75,
        borderRadius: "50%",
        margin: theme.spacing(2),
        // padding: theme.spacing(0.5),
        background: theme.palette.grey[300]
        // border: "1px solid " + theme.palette.primary.light
    },
    noUnderlinedLink: {
        textDecoration: "none"
    },
    infoIcon: {
        height: 15,
        width: 15,
        left: 24,
        position: "absolute"
    }
});

export default componentStyles;
