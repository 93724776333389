import { BaseValuationState, initialBaseValuationState } from "./base-valuation";
import { BrokerEntryState, initialBrokerEntryState } from "./broker-entry";
import { CardState, initialCardState } from "./card-value";
import { initialPageHistoryState, PageHistoryState } from "./page-history";
import { initialPropertyState, PropertyState } from "./property-type";
import { initialRealEstateState, RealEstateState } from "./real-estate";
import { SharedReduxState, initialSharedState } from "@onpreo/slices";
import { ExpertModeState, initialExpertModeState } from "./expert-mode";
import { UserState, initialUserState } from "./user";
import { SlideChangeState, initialSlideChangeState } from "./change-on-slide";
import { SideBarState, initialSideBarState } from "./sideBar-value";
import { completedStepsState, initialCompletedStepsState } from "./completed-steps";

export default interface ReduxState extends SharedReduxState {
    realEstate: RealEstateState;
    property: PropertyState;
    baseValuation: BaseValuationState;
    slideChange: SlideChangeState;
    brokerEntry: BrokerEntryState;
    pageHistory: PageHistoryState;
    expertMode: ExpertModeState;
    cardValue: CardState;
    user: UserState;
    sideBarValue: SideBarState;
    completedSteps: completedStepsState;
}

export const initialReduxState: ReduxState = {
    realEstate: initialRealEstateState,
    property: initialPropertyState,
    baseValuation: initialBaseValuationState,
    slideChange: initialSlideChangeState,
    brokerEntry: initialBrokerEntryState,
    pageHistory: initialPageHistoryState,
    expertMode: initialExpertModeState,
    cardValue: initialCardState,
    user: initialUserState,
    sideBarValue: initialSideBarState,
    completedSteps: initialCompletedStepsState,
    ...initialSharedState
};
