import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";

export const expertModeSlice = createSlice({
    name: "expertMode",
    initialState: initialReduxState.expertMode,
    reducers: {
        setPropertyFilledTypes: (state, action: PayloadAction<{ [x: string]: boolean }>) => {
            state.filledTypes = { ...state.filledTypes, ...action.payload };
            return state;
        },
        setActivePropertyType: (state, action: PayloadAction<typeof initialReduxState.expertMode.activePropertyType>) => {
            state.activePropertyType = action.payload;
        },
        setBasicStep: (state, action: PayloadAction<typeof initialReduxState.expertMode.basicStep>) => {
            state.basicStep = action.payload;
        },
        setOrder: (state, action) => {
            state.order.push(action.payload);
        },
        unsetOrder: (state, action) => {
            if (state.order[state.order.length - 1] === action.payload) state.order.pop();
        },
        setPriceAssistant: (state, action) => {
            state.priceAssistant = action.payload;
        }
    }
});

export const { setPropertyFilledTypes, setActivePropertyType, setBasicStep, setPriceAssistant, setOrder, unsetOrder } = expertModeSlice.actions;

export default expertModeSlice.reducer;
