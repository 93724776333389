export const NODE_ENV = process.env.NODE_ENV;
export const MONGO_URL = process.env.MONGO_URL;

export const MJ_APIKEY_PUBLIC = process.env.MJ_APIKEY_PUBLIC;
export const MJ_APIKEY_PRIVATE = process.env.MJ_APIKEY_PRIVATE;

export const JWT_SECRET = process.env.JWT_SECRET;
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET;

export const AWS_ACCESS_KEY = process.env.ONPREO_AWS_ACCESS_KEY;
export const AWS_BUCKET = process.env.AWS_BUCKET;
export const AWS_SECRET_ACCESS_KEY = process.env.ONPREO_AWS_SECRET_ACCESS_KEY;
export const AWS_BUCKET_PRIVATE = process.env.AWS_BUCKET_PRIVATE;
export const PROTOCOL = NODE_ENV === "development" ? "http://" : "https://";
export const AWS_REGION = "eu-central-1";
export const AWS_IMAGE_URL = `${PROTOCOL}${AWS_BUCKET}.s3.${AWS_REGION}.amazonaws.com/`;

export const STRIPE_PUB_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY;
export const STRIPE_SECRET_KEY = process.env.STRIPE_SECRET_KEY;

export const SPRENGNETTER_USER = process.env.SPRENGNETTER_USER;
export const SPRENGNETTER_PW = process.env.SPRENGNETTER_PW;

export const GOOGLE_API_KEY = process.env.NEXT_APP_GOOGLE_API_KEY;

export const GUEST_APP_URL = process.env.NEXT_PUBLIC_GUEST_APP_URL;
export const BROKER_APP_URL = process.env.NEXT_PUBLIC_BROKER_APP_URL;
export const PRICE_ASSISTANT_URL = process.env.NEXT_PUBLIC_PRICE_ASSISTANT_URL;
export const SMASHLEADS_URL = process.env.NEXT_PUBLIC_SMASHLEADS_URL;
export const FEATURE_SIDEBAR = process.env.NEXT_PUBLIC_FEATURE_SIDEBAR === "true";

export const USERPILOT_KEY = process.env.NEXT_PUBLIC_USERPILOT_KEY;
export const USERPILOT_ACTIVE_TRACKING = process.env.NEXT_PUBLIC_USERPILOT_ACTIVE_TRACKING;
