import { ContactDocument, RealEstateDocument } from "@onpreo/database";

export type ContactState = { currentContact: ContactDocument; guests: ContactDocument[]; mode: "default" | "workspace" | "demo" };

export const initialContactState = {
    currentContact: {
        meta: {
            name: "James Bond",
            email: "namesbond@007.com",
            phone: "",
            address: {
                street: "",
                town: "",
                nation: "",
                zip: "",
                house_number: ""
            }
        },
        workspace: {},
        realEstates: []
    } as ContactState["currentContact"],
    guests: [] as ContactState["guests"],
    mode: "default" as "default" | "workspace" | "demo"
};
