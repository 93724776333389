import { ContactState, initialContactState } from "./contact.state";
import { initialRealEstateState, RealEstateState } from "./real-estate.state";
import { AgentState, initialAgentState } from "./agent.state";
import { SharedReduxState, initialSharedState } from "@onpreo/slices";
import { initialRealEstateChangesState, RealEstateChangesState } from "./real-estate-changes";

export default interface ReduxState extends SharedReduxState {
    contact: ContactState;
    realEstate: RealEstateState;
    agent: AgentState;
    realEstatesChanges: RealEstateChangesState;
}

export const initialReduxState: ReduxState = {
    contact: initialContactState,
    realEstate: initialRealEstateState,
    agent: initialAgentState,
    realEstatesChanges: initialRealEstateChangesState,
    ...initialSharedState
};
