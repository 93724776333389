import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RealEstateDocument } from "@onpreo/database";
import { initialReduxState } from "../models";
import { RealEstateState } from "../models/real-estate.state";

export const realEstateSlice = createSlice({
    name: "realEstate",
    initialState: initialReduxState.realEstate,
    reducers: {
        setRealEstate: (state, action: PayloadAction<{ active: number }>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        setGuestRealEstates: (state, action: PayloadAction<RealEstateDocument[]>) => {
            // @ts-ignore
            state.guestRealEstates = action.payload;
            return state;
        },
        updateRealEstate: (state, action: PayloadAction<{ id: string; realEstate: RealEstateDocument }>) => {
            const { id, realEstate } = action.payload;
            // @ts-ignore
            const index = state.guestRealEstates.findIndex(r => r._id === id);

            console.log(realEstate);
            // @ts-ignore
            if (index !== -1) state.guestRealEstates[index] = realEstate;
            else console.error("couldnt update real estate", id, realEstate);
        }
    }
});

export const { setRealEstate, updateRealEstate, setGuestRealEstates } = realEstateSlice.actions;

export default realEstateSlice.reducer;
