export type SlideChangeState = {
    priceChangeArea: number;
    priceChangeTypeRealEstate: number;
    priceChangeHouseType1: number;
    priceChangeHouseType2: number;
    priceChangeRooms: number;
    priceChangeYear: number;
    priceChangeMaintenance: number;
    priceChangeUse: number;
    priceChangeBasement: number;
    priceChangeParking: number;
    priceChangeBalcony: number;
    priceChangeGarden: number;
    priceChangeImpairment: number;
    priceChangeMarket: number;
    priceChangeAnleger: number;
    priceChangeStrategy: number;
    priceChangeWohnung: number;
    priceChangeFloors: number;
    priceChangeErtragswert: number;
    priceChangeDach: number;
    priceChangeFassade: number;
    priceChangeHeizung: number;
    priceChangeGraphs: number;
    priceChangeRennovation: number;
    priceChangeProvision: number;
    priceChangeSanierung: number;
    priceChangeMarketDevelopment: number;
    priceChangeEquipment: number;
};

export const initialSlideChangeState = {
    priceChangeArea: 0,
    priceChangeTypeRealEstate: 0,
    priceChangeHouseType1: 0,
    priceChangeHouseType2: 0,
    priceChangeRooms: 0,
    priceChangeYear: 0,
    priceChangeMaintenance: 0,
    priceChangeUse: 0,
    priceChangeBasement: 0,
    priceChangeParking: 0,
    priceChangeBalcony: 0,
    priceChangeGarden: 0,
    priceChangeImpairment: 0,
    priceChangeMarket: 0,
    priceChangeAnleger: 0,
    priceChangeStrategy: 0,
    priceChangeWohnung: 0,
    priceChangeFloors: 0,
    priceChangeErtragswert: 0,
    priceChangeDach: 0,
    priceChangeFassade: 0,
    priceChangeHeizung: 0,
    priceChangeGraphs: 0,
    priceChangeRennovation: 0,
    priceChangeProvision: 0,
    priceChangeSanierung: 0,
    priceChangeMarketDevelopment: 0,
    priceChangeEquipment: 0
};
