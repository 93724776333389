export type BaseValuationState = {
    basePriceArea: number;
    basePriceTypeRealEstate: number;
    basePriceHouseType1: number;
    basePriceHouseType2: number;
    basePriceRooms: number;
    basePriceYear: number;
    basePriceMaintenance: number;
    basePriceUse: number;
    basePriceBasement: number;
    basePriceParking: number;
    basePriceBalcony: number;
    basePriceGarden: number;
    basePriceImpairment: number;
    basePriceMarket: number;
    basePriceAnleger: number;
    basePriceStrategy: number;
    basePriceWohnung: number;
    basePriceFloors: number;
    basePriceErtragswert: number;
    basePriceDach: number;
    basePriceFassade: number;
    basePriceHeizung: number;
    basePriceGraphs: number;
    basePriceRennovation: number;
    basePriceProvision: number;
    basePriceSanierung: number;
    basePriceMarketDevelopment: number;
    tempPrice: number; // just for PF stragtegy use
    estimatedDemand: string;
    currentRealEstateValue: number;
    brokerEntryRenovationTemporary: number;
    renovationSign: string;
};

export const initialBaseValuationState = {
    basePriceArea: 0,
    basePriceTypeRealEstate: 0,
    basePriceHouseType1: 0,
    basePriceHouseType2: 0,
    basePriceRooms: 0,
    basePriceYear: 0,
    basePriceMaintenance: 0,
    basePriceUse: 0,
    basePriceBasement: 0,
    basePriceParking: 0,
    basePriceBalcony: 0,
    basePriceGarden: 0,
    basePriceImpairment: 0,
    basePriceMarket: 0,
    basePriceAnleger: 0,
    basePriceStrategy: 0,
    basePriceWohnung: 0,
    basePriceFloors: 0,
    basePriceErtragswert: 0,
    basePriceDach: 0,
    basePriceFassade: 0,
    basePriceHeizung: 0,
    basePriceGraphs: 0,
    basePriceRennovation: 0,
    basePriceProvision: 0,
    basePriceSanierung: 0,
    basePriceMarketDevelopment: 0,
    tempPrice: 0,
    estimatedDemand: "moderat",
    currentRealEstateValue: 0,
    brokerEntryRenovationTemporary: 0,
    renovationSign: "+"
};
