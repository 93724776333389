import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { BrokerEntryState } from "../models/broker-entry";

export const brokerEntrySlice = createSlice({
    name: "brokerEntry",
    initialState: initialReduxState.brokerEntry,
    reducers: {
        setBrokerEntryState: (state, action: PayloadAction<BrokerEntryState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setBrokerEntryState } = brokerEntrySlice.actions;

export default brokerEntrySlice.reducer;
