import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { BaseValuationState } from "../models/base-valuation";

export const baseValuationSlice = createSlice({
    name: "baseValuation",
    initialState: initialReduxState.baseValuation,
    reducers: {
        setBaseValuation: (state, action: PayloadAction<BaseValuationState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setBaseValuation } = baseValuationSlice.actions;

export default baseValuationSlice.reducer;
