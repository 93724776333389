import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { PageHistoryState } from "../models/page-history";

export const pageHistorySlice = createSlice({
    name: "pageHistory",
    initialState: initialReduxState.pageHistory,
    reducers: {
        setPageHistoryState: (state, action: PayloadAction<PageHistoryState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setPageHistoryState } = pageHistorySlice.actions;

export default pageHistorySlice.reducer;
