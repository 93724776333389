import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { CardState } from "../models/card-value";

export const cardValueSlice = createSlice({
    name: "cardValue",
    initialState: initialReduxState.cardValue,
    reducers: {
        setCardState: (state, action: PayloadAction<CardState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setCardState } = cardValueSlice.actions;

export default cardValueSlice.reducer;
