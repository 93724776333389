import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { SideBarState } from "../models/sideBar-value";

export const sideBarValueSlice = createSlice({
    name: "sideBarValue",
    initialState: initialReduxState.sideBarValue,
    reducers: {
        setSideBarState: (state, action: PayloadAction<SideBarState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setSideBarState } = sideBarValueSlice.actions;

export default sideBarValueSlice.reducer;
