import { RealEstate } from "@onpreo/database/src/definitions/real-estate";

export type RealEstateState = Omit<RealEstate, "user" | "images" | "expose"> & { initialConstructionYear?: number; initialPlotArea?: number };

export const initialRealEstateState = {
    salesProgress: "AGREE_TO_START_PRICE",
    category: "ETW",
    address: {
        nation: "",
        street: "",
        house_number: "",
        zip: "",
        town: ""
    },
    numberOfRequests: 0,
    askingPrice: {
        evaluation: null,
        range: {
            high: 0,
            low: 0
        },
        manualPFPriceChange: false
    }
} as RealEstateState;
