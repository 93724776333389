import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { completedStepsState } from "../models/completed-steps";

export const completedStepsSlice = createSlice({
    name: "completedSteps",
    initialState: initialReduxState.completedSteps,
    reducers: {
        setCompletedStepsState: (state, action: PayloadAction<completedStepsState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setCompletedStepsState } = completedStepsSlice.actions;

export default completedStepsSlice.reducer;
