import { createSelector } from "reselect";
import ReduxState from "../models";
import { getPropertyType } from "./property.selectors";

export const getRealEstate = (state: ReduxState) => state.realEstate;
export const getWorkspace = createSelector(getRealEstate, realEstate => realEstate?.workspace);
export const getWorkspacePrimary = createSelector(getWorkspace, w => w?.publication?.primary);
export const getWorkspaceLogo = createSelector(getWorkspace, w => w?.extras?.logo);
export const getLogoUseInPriceAssistant = createSelector(getWorkspace, w => w?.extras?.use?.forPriceAssistant);

export const getAddress = createSelector(getRealEstate, realEstate => realEstate.address);

export const getAskingPrice = createSelector(getRealEstate, realEstate => realEstate.askingPrice);

export const getEstimate = createSelector(getRealEstate, realEstate => realEstate.askingPrice?.estimate);

export const getLivingArea = createSelector(getRealEstate, realEstate => realEstate.livingArea);

export const getCommercialSpace = createSelector(getRealEstate, realEstate => realEstate.commercialSpace);

export const getPlotArea = createSelector(getRealEstate, realEstate => realEstate.plotArea);

export const getFloors = createSelector(getRealEstate, realEstate => realEstate.floor);

export const getFloorNumber = createSelector(getRealEstate, realEstate => realEstate.floorNumber);

export const getRooms = createSelector(getRealEstate, realEstate => realEstate.rooms);

export const getYear = createSelector(getRealEstate, realEstate => realEstate.constructionYear);

export const getCondition = createSelector(getRealEstate, realEstate => realEstate.condition);

export const getHasBasement = createSelector(getRealEstate, realEstate => realEstate.basement);

export const getHasParking = createSelector(getRealEstate, realEstate => realEstate.outdoorParkingSpace);

export const getHasRenovation = createSelector(getRealEstate, realEstate => realEstate.hasRenovationDone);

export const getHasVisualRenovation = createSelector(getRealEstate, realEstate => realEstate.hasVisualRenovationDone);

export const getExtras = createSelector(getRealEstate, realEstate => realEstate.extras);

export const getHasBalcony = createSelector(getRealEstate, realEstate => realEstate.extras?.balcony);

export const getHasTerrace = createSelector(getRealEstate, realEstate => realEstate.extras?.terrace);

export const getHasGarden = createSelector(getRealEstate, realEstate => realEstate.extras?.garden);

export const getAdditionalFeatures = createSelector(getRealEstate, realEstate => realEstate.additionalFeatures);

export const getCustomServices = createSelector(getRealEstate, realEstate => realEstate.customServices);

export const getPotentialBuyers = createSelector(getRealEstate, realEstate => realEstate.potentialBuyers);

export const getMaintenanceYear = createSelector(getRealEstate, realEstate => realEstate.maintenanceYear);

export const getRealEstateCat = createSelector(getRealEstate, realEstate => realEstate.category);

export const getRealEstateConstruction = createSelector(getRealEstate, realEstate => realEstate.construction);

export const getLastRenovated = createSelector(getRealEstate, realEstate => realEstate.lastRenovated);

export const getEquipment = createSelector(getRealEstate, realEstate => realEstate.equipment);

export const getEquipmentHeating = createSelector(getRealEstate, realEstate => realEstate.equipment?.heating);

export const getProvisionRate = createSelector(getRealEstate, realEstate => realEstate.provisionRate);

export const getRent = createSelector(getRealEstate, realEstate => realEstate.rent);

export const getEquipmentValue = createSelector(getEquipment, equipment => equipment?.value);

export const getCurrency = createSelector(getRealEstate, re => {
    if (re.askingPrice?.currency === "CHF") return "CHF";
    else return " €";
});

export const getCurrencyCode = createSelector(getRealEstate, re => re.askingPrice?.currency);

export const getActivePriceAssistant = createSelector(getRealEstate, realEstate => realEstate.priceAssistant);

export const getTypeRealEstatePrice = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.buildingTypePrice;
    if (type === "Wohnung") return priceAssistant?.apartment?.buildingTypePrice;
    if (type === "Mehrfamilienhaus") return priceAssistant?.building?.buildingTypePrice;
});

export const getPriceAssistantHouse = createSelector(getActivePriceAssistant, priceAssistant => priceAssistant?.house);
export const getPAHouseRooms = createSelector(getPriceAssistantHouse, house => house?.rooms);
export const getPAHouseFloor = createSelector(getPriceAssistantHouse, house => house?.floor);
export const getPAHouseConstructionYear = createSelector(getPriceAssistantHouse, house => house?.constructionYear);
export const getPAHouseRenovationYear = createSelector(getPriceAssistantHouse, house => house?.renovation);
export const getPAHouseCondition = createSelector(getPriceAssistantHouse, house => house?.condition);
export const getPAUse = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.use;
    if (type === "Wohnung") return priceAssistant?.apartment?.use;
});
export const getBasementPriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.basement;
    if (type === "Wohnung") return priceAssistant?.apartment?.basement;
});
export const getParkingPriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.parking;
    if (type === "Wohnung") return priceAssistant?.apartment?.parking;
});
export const getRenovationPriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.renovation;
    if (type === "Wohnung") return priceAssistant?.apartment?.renovation;
    if (type === "Mehrfamilienhaus") return priceAssistant?.building?.renovation;
});
export const getBalconyPriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.balcony;
    if (type === "Wohnung") return priceAssistant?.apartment?.balcony;
});
export const getTerracePriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.terrace;
    if (type === "Wohnung") return priceAssistant?.apartment?.terrace;
});
export const getGardenPriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.garden;
    if (type === "Wohnung") return priceAssistant?.apartment?.garden;
});
export const getFeaturesPriceByType = createSelector([getActivePriceAssistant, getPropertyType], (priceAssistant, type) => {
    if (type === "Haus") return priceAssistant?.house?.features;
    if (type === "Wohnung") return priceAssistant?.apartment?.features;
    if (type === "Mehrfamilienhaus") return priceAssistant?.building?.features;
});

export const getPriceAssistantApartment = createSelector(getActivePriceAssistant, priceAssistant => priceAssistant?.apartment);
export const getPAApartmentConstructionYear = createSelector(getPriceAssistantApartment, apartment => apartment?.constructionYear);
export const getPAApartmentRenovationYear = createSelector(getPriceAssistantApartment, apartment => apartment?.renovation);
export const getPAApartmentCondition = createSelector(getPriceAssistantApartment, apartment => apartment?.condition);
export const getPAApartmentType = createSelector(getPriceAssistantApartment, apartment => apartment?.apartmentType);
export const getPAApartmentRooms = createSelector(getPriceAssistantApartment, apartment => apartment?.rooms);

export const getPriceAssistantBuilding = createSelector(getActivePriceAssistant, priceAssistant => priceAssistant?.building);
export const getPABuildingLivingArea = createSelector(getPriceAssistantBuilding, building => building?.livingArea);
export const getPABuildingCommercialSpace = createSelector(getPriceAssistantBuilding, building => building?.commercialSpace);
export const getPABuildingFloor = createSelector(getPriceAssistantBuilding, building => building?.floor);
export const getPABuildingRoof = createSelector(getPriceAssistantBuilding, building => building?.roof);
export const getPABuildingFacade = createSelector(getPriceAssistantBuilding, building => building?.facade);
export const getPABuildingHeating = createSelector(getPriceAssistantBuilding, building => building?.heating);
export const getPABuildingHeatingTypes = createSelector(getPriceAssistantBuilding, building => building?.heatingTypes);
export const getPABuildingRenovationYear = createSelector(getPriceAssistantBuilding, building => building?.renovation);
export const getPABuildingCondition = createSelector(getPriceAssistantBuilding, building => building?.condition);

export const getPriceAssistantEmailSendoutChange = createSelector(getActivePriceAssistant, priceAssistant => priceAssistant?.emailSendoutChange);

export const getMarketPriceAdjustmentFactor = createSelector(getRealEstate, realEstate => realEstate?.marketPriceAdjustmentFactor);
export const getInvitationSelected = createSelector(getRealEstate, realEstate => realEstate?.invitationSelected);
export const getWorkspaceInvitationSelected = createSelector(getWorkspace, workspace => workspace?.invitationSelected);

export const getPriceAdjustmentFactor = createSelector(getRealEstate, realEstate => realEstate?.marketPriceAdjustmentFactor);

export const getBuyerBurdenData = createSelector(getRealEstate, re => re?.buyerBurdenData);
