import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { UserState } from "../models/user";

export const userSlice = createSlice({
    name: "user",
    initialState: initialReduxState.user,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            // @ts-ignore
            state = action.payload;
            return state;
        }
    }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
