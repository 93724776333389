import { useRouter } from "next/router";
import { useEffect } from "react";
import { Userpilot } from "userpilot";
import { useReduxSelector } from "../../store/selectors";
import { getUser } from "../../store/selectors/user.selectors";
import { USERPILOT_ACTIVE_TRACKING, USERPILOT_KEY } from "../../utils/secrets";

// Initialize Userpilot
Userpilot.initialize(USERPILOT_KEY);

const UserPilot = () => {
    const router = useRouter();
    const user = useReduxSelector(getUser);

    useEffect(() => {
        if (user?.id) {
            console.log("updating userpilot user");
            if (USERPILOT_ACTIVE_TRACKING)
                Userpilot.identify(user.id, {
                    name: user?.name,
                    email: user?.email,
                    company: {
                        id: user?.workspaceId,
                        name: user?.company ?? "Unknown Company Name"
                    }
                });
        }
    }, [user]);

    useEffect(() => {
        if (user?.id) {
            Userpilot.reload();
        }
    }, [router.route]);

    return <div id="user-pilot-helper" />;
};

export const userPilotTrigger = {
    tourStart: () => Userpilot.trigger("1676491254mMdr0294")
};

export default UserPilot;
