import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialReduxState } from "../models";
import { PropertyState } from "../models/property-type";

export const propertySlice = createSlice({
    name: "property",
    initialState: initialReduxState.property,
    reducers: {
        setProperty: (state, action: PayloadAction<PropertyState>) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setProperty } = propertySlice.actions;

export default propertySlice.reducer;
