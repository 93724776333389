import { configureStore } from "@reduxjs/toolkit";
import realEstateReducer from "./slices/real-estate.slice";
import propertyReducer from "./slices/property.slice";
import baseValuationReducer from "./slices/base-valuation.slice";
import slideChangeReducer from "./slices/change-on-slide.slice";
import brokerEntryReducer from "./slices/broker-entry.slice";
import pageHistorReducer from "./slices/page-history.slice";
import expertModeReducer from "./slices/expert-mode.slice";
import cardValueReducer from "./slices/card-value.slice";
import userReducer from "./slices/user.slice";
import sideBarValueReducer from "./slices/sideBar-value.slice";
import { sharedReducer } from "@onpreo/slices";
import { injectStore } from "@onpreo/upsy-daisy/client";
import { useDispatch } from "react-redux";
import { initialReduxState } from "./models";
import { slideChangeSlice } from "./slices/change-on-slide.slice";
import completedStepsReducer from "./slices/completed-steps.slice";

let store: ReturnType<typeof createStore>;

const createStore = (preloadedState: any) => {
    return configureStore({
        preloadedState: preloadedState ?? initialReduxState,
        reducer: {
            realEstate: realEstateReducer,
            property: propertyReducer,
            baseValuation: baseValuationReducer,
            slideChange: slideChangeReducer,
            brokerEntry: brokerEntryReducer,
            pageHistory: pageHistorReducer,
            expertMode: expertModeReducer,
            cardValue: cardValueReducer,
            user: userReducer,
            sideBarValue: sideBarValueReducer,
            completedSteps: completedStepsReducer,
            ...sharedReducer
        }
        // middleware: getDefaultMiddleware => getDefaultMiddleware.concat(OWN_MIDDLEWARE_GOES_HERE)
    });
};

export const initStore = (preloadedState?: any) => {
    let _store = store ?? createStore(preloadedState);
    if (preloadedState && store) {
        _store = createStore({
            ...store.getState(),
            ...preloadedState
        });
        store = undefined;
    }

    // create a new store for SSG and SSR
    if (typeof window === "undefined") return _store;
    // Create the store once in the client
    if (!store) {
        store = _store;
        injectStore(store);
    }

    return _store;
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
